<template>
  <div>
    <div class="row">
      <div class="header" data-aos="fade-up" data-aos-delay="300">
        <img src="/img/logo.svg" class="col-1 logo" />
        <dropdown></dropdown>
      </div>
    </div>
    <center v-if="loading" class="loading">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </center>
    <section class="main__content--frames" id="container" v-else>
      <div class="row d-none d-md-flex">
        <div class="col-12">
          <div id="switch" v-if="event.id">
            <b-form-checkbox
              v-model="chatSwitchStatus"
              switch
              @change="toggleChat"
              v-if="$root.capabilities == 10"
            >
              <strong>{{ chatActiveMsg }}</strong>
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <template v-if="!loading && !userCanAccessEvent">
        <center class="mensaje info">
          <p>
            Lamentablemente, no tienes acceso a este evento. Comunícate con tu
            discipulador.
          </p>
        </center>
      </template>
      <div
        v-if="event.id && userCanAccessEvent"
        id="frames"
        class="row"
        data-aos="fade-up"
        data-aos-delay="500"
      >
        <EventVideo></EventVideo>
        <div class="bntv__chat-wrapper" v-bind:class="chatClass">
          <chat ref="chat" v-if="userCanChat"></chat>
        </div>
        <div class="event-data">
          <h2 class="titulo_evento">{{ event.title }}</h2>
          <div class="subline"></div>
          <h6>Iglesia Ministerio Levantando a Cristo</h6>
          <p class="today-date">{{ todayDate() }}</p>
        </div>
        <p class="mensaje info" v-if="!event.chat">
          El chat para este evento está desactivado
        </p>
        <p v-if="userIsAdmin" class="mensaje counter">
          <b-link v-b-modal.connections
            >Conexiones Activas: {{ connections.length }}</b-link
          >
        </p>
      </div>
      <div v-if="!event.id && userCanAccessEvent">
        <div class="background">
          <center class="mensaje info">
            <h1
              class="buenas-nuevas-heading"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              Buenas Nuevas TV
            </h1>
            <h5
              class="buenas-nuevas-subheading"
              data-aos="fade-up"
              data-aos-delay="700"
            >
              En estos momentos no estamos transmitiendo.
            </h5>
          </center>
        </div>
      </div>
    </section>
    <survey />
  </div>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import moment from "moment";

import chat from "../components/Chat";
import dropdown from "../components/Dropdown";
import environment from "../../env";
import EventVideo from "../components/EventVideo.vue";
import groupsApi from "../api/groups";
import io from "socket.io-client";
import { mapGetters } from "vuex";
import userService from "../services/user";
import authService from "@/services/auth";
import survey from "../components/Survey.vue";
import { version } from "../../package.json";

AOS.init();

export default {
  components: {
    chat,
    dropdown,
    EventVideo,
    survey,
  },
  data: function () {
    return {
      nombre: "home",
      chat: "",
      capabilities: "",
      loading: true,
      conectado: false,
      classVideo: "",
      classChat: "",
      mensaje: "",
      connection_id: null,
      chatSwitchStatus: false,
      userCanAccessEvent: true,
      userCanChat: true,
    };
  },
  created: function () {
    const ctx = this;
    if (this.$root.capabilities != 10) {
      document.oncontextmenu = function () {
        return false;
      };
    }

    if (window.localStorage.invitado) {
      const invitado = JSON.parse(window.localStorage.invitado);
      this.$root.user = invitado;
    }

    if (!authService.isStoredDataComplete(this.$root.user)) {
      authService.clearLocalStorage();
      this.$router.push("/login");
    }

    let display_name = this.$root.user?.display_name;
    let id = this.$root.user?.ID || this.$root.user?.invitacion_id;
    let email = this.$root.user?.user_email || this.$root.user?.email;
    let type = this.$root.user?.ID ? "usuario" : "invitado";

    this.$root.socket = io(
      environment.environment.ws_url +
        "?user=" +
        display_name +
        "&id=" +
        id +
        "&type=" +
        type +
        "&email=" +
        encodeURIComponent(email) +
        "&version=" +
        version
    );

    // eslint-disable-next-line no-unused-vars
    this.$root.socket.on("transmision", function (value) {
      console.debug("socket.on: Start Event");
      ctx.getActiveEvent();
    });

    this.$root.socket.on("disconnect-guests", (id) => {
      console.debug("socket.on: Disconnected guests");
      // Si es para un invitado específico
      if (
        id &&
        ctx.$root.user.invitacion_id &&
        ctx.$root.user?.invitacion_id == id
      ) {
        ctx.logout();
      }

      // Si es para todos los invitados
      if (!id && ctx.$root.user.invitacion_id) {
        ctx.logout();
      }
    });

    this.$root.socket.on("toggle-chat", () => {
      console.debug("socket.on: Toggle chat");
      this.getActiveEvent();
    });

    this.$root.socket.on("update-user-status", (user) => {
      console.debug("socket.on: Update user status");
      if (user.id === this.$root.user.id) this.getActiveEvent();
    });

    this.$root.socket.on("update_users", (data) => {
      console.debug("socket.on: Update users");
      this.$store.commit("connections/updateConnections", data);
    });

    // para manejar el status del chat a nivel de visita
    this.$root.socket.on("update-users-status", (data) => {
      console.debug("socket.on: Update users status");
      if (data.id === this.$root.user.invitacion_id) {
        this.$root.user.can_chat = data.can_chat;
        this.getActiveEvent();
      }
    });

    this.$root.socket.on("survey", () => {
      console.debug("socket.on: Survey");
      this.getActiveSurvey();
    });

    this.getActiveEvent();
  },
  mounted() {
    window.onresize = () => {
      this.width = window.innerWidth;
    };
    setTimeout(() => {
      this.chatEnabled = this.event.chat === 1;
    }, 1000);
    this.getActiveSurvey();
  },
  computed: {
    ...mapGetters({
      event: "events/getActiveEvent",
      connections: "connections/getConnections",
    }),
    chatActiveMsg: function () {
      return this.chatEnabled ? "Desactivar Chat" : "Activar Chat";
    },
    chatClass: function () {
      if (this.event.chat) {
        return "col-md-5 col-sm-12";
      }
      return "col-sm-12";
    },
    userIsAdmin: function () {
      return this.$root.capabilities === "10";
    },
  },
  methods: {
    canChat: function () {
      const eventHasChat = !!this.event.chat;
      const userAbilityToChat =
        !this.$root.user.invitacion_id ||
        (this.$root.user.invitacion_id && this.$root.user.can_chat);

      this.userCanChat = userAbilityToChat && eventHasChat;
    },
    getActiveEvent: function () {
      this.$store
        .dispatch("events/getActiveEvent")
        .then(() => {
          if (this.event.id && Object.keys(this.event).length !== 0) {
            this.chatSwitchStatus = !!this.event.chat;
            this.canChat();
            if (userService.userIsGuest(this.$root.user)) {
              this.userCanAccessEvent = true;
              this.loading = false;
            } else {
              this.checkUserAccess();
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          if (e.message.search("403") === -1) {
            this.$alert(
              "No se ha podido cargar el video, contáctate con el administrador.",
              "Oops!",
              "error"
            );
          } else {
            this.loading = false;
            this.userCanAccessEvent = false;
          }
        });
    },
    getActiveSurvey: function () {
      // TODO: implementar FF y/o otro mecanismo para evitar este llamado en cada request
      // this.$store.dispatch("survey/getActiveSurvey");
    },
    checkUserAccess: function () {
      if (this.$root.user.ID && this.event.event_group_id) {
        groupsApi.byUser(this.$root.user.ID).then((res) => {
          this.$root.user.groups = res.data;
          this.userCanAccessEvent = userService.userCanAccessEvent(
            this.$root.user,
            this.event
          );
          this.loading = false;
        });
      }
    },
    toggleChat: function () {
      const switchValue = this.chatSwitchStatus;
      var confirm = switchValue
        ? "Se activará el chat para todos los usuarios"
        : "Se desactivará el chat para todos los usuarios";
      this.chatSwitchStatus = !switchValue;
      this.$confirm(confirm).then(() => {
        this.chatSwitchStatus = switchValue;
        this.$store
          .dispatch("events/updateEvent", {
            chat: switchValue,
          })
          .then(() => {
            this.$store.dispatch("events/getActiveEvent").then(() => {
              console.debug("socket.emit: Toggle chat");
              this.$root.socket.emit("toggle-chat");
            });
          });
      });
    },
    logout: function () {
      authService.logout(this.$root.socket, this.$router);
      delete this.$root.user;
    },
    todayDate() {
      const date = `${moment().format("dddd")} ${moment().format(
        "D"
      )} de ${moment().format("MMMM")}`;
      const capitalizedDate = date.charAt(0).toUpperCase() + date.slice(1);
      return capitalizedDate;
    },
  },
};
</script>

<style>
.background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding-top: 15vh;
  top: 0;
  left: 0;
  background: url("../../public/img/background.svg");
  background-size: cover;
  background-position: bottom;
  overflow: hidden;
  z-index: -1;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
}

.header img {
  max-width: 90px;
}

.buenas-nuevas-heading {
  margin-top: 10%;
  background: #36c9ff;
  background: -webkit-linear-gradient(to right, #36c9ff 0%, #b342cf 85%);
  background: -moz-linear-gradient(to right, #36c9ff 0%, #b342cf 85%);
  background: linear-gradient(to right, #36c9ff 0%, #b342cf 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 80px;
  font-weight: bold;
  z-index: 100;
}

.buenas-nuevas-subheading {
  font-size: 25px;
  color: #fff;
}

.info h6,
.info p {
  margin: 0;
  font-size: 16px;
}

.event-data {
  color: #fff;
}

.titulo_evento {
  color: #fff;
  font-size: 40px;
}
.subline {
  background-color: #00b7ff;
  width: inherit;
  height: 5px;
  margin-bottom: 10px;
}

#switch {
  color: #fff;
  position: absolute;
  bottom: 10px;
  right: 15px;
  z-index: 102;
}

#switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #00b7ff !important;
}

#menu {
  float: right;
}

#modalInvitacion small {
  color: brown;
}

#frames {
  position: relative !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.main__content--frames {
  padding-top: 25px;
  width: 100%;
}

.mensaje.counter {
  color: #fff;
  width: 100%;
}

.mensaje.counter a {
  color: #ffffff;
  text-decoration: none;
}

.mensaje.counter a:hover {
  color: white;
  text-decoration: none;
}

.mensaje.info {
  color: white;
}

.loading {
  margin-top: 100px;
}
@media (max-width: 1139px) {
  .event-data {
    display: none;
  }
}

@media (max-width: 1000px) {
  .buenas-nuevas-heading {
    margin-top: 20%;
    font-size: 60px;
  }

  .buenas-nuevas-subheading {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .main__content--frames {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .header {
    padding: 1rem 1rem 1rem 0;
  }

  .header img {
    max-width: 80px;
  }

  .buenas-nuevas-heading {
    margin-top: 30%;
    font-size: 35px;
  }

  .buenas-nuevas-subheading {
    font-size: 14px;
  }
}
</style>
